export default {
  namespaced: true,

  state: {
    info: null,
    wsUrl: null
  },

  mutations: {
    set(state: any, payload: any) {
      state.info = payload;
    },
    setWsUrl(state: any, payload: string) {
      state.wsUrl = payload;
    },
    remove(state: any) {
      state.info = null;
    }
  },

  actions: {
    set(context: any, payload: any) {
      context.commit("set", payload);
    },
    setWsUrl(context: any, payload: string) {
      context.commit("setWsUrl", payload);
    },
    remove(context: any) {
      context.commit("remove");
    }
  },

  getters: {
    info: (state: any): boolean => state.info,
    wsUrl: (state: any): boolean => state.wsUrl
  }
};
