const meta = {
  module: "users"
};

export default [
  {
    path: `/users`,
    redirect: "/users/page/1"
  },
  {
    path: `/users/page`,
    redirect: "/users/page/1"
  },
  {
    path: `/users/page/:page`,
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/users/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
