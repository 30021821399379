import Auth from "@/api/system/Auth";
import Users from "@/api/system/Users";
import APM from "@/api/system/APM";
import Cards from "@/api/system/Cards";
import Clients from "@/api/system/Clients";
import Operators from "@/api/system/Operators";

export default {
  auth: () => new Auth(),
  users: () => new Users(),
  apm: () => new APM(),
  cards: () => new Cards(),
  clients: () => new Clients(),
  operators: () => new Operators()
};
