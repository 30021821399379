import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import themes from "@/themes/default/colors";
import store from "@/store";
import ru from "vuetify/src/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: store.getters["localization/getCurrent"]
  },
  theme: {
    options: {
      customProperties: true
    },
    themes
  }
});
