import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Api from "@/api/API";
import confirm from "@/directives/confirm";
import i18n from "./plugins/i18n";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./plugins/ability";
import VueApexCharts from "vue-apexcharts";
// import Pusher from "./services/pusher";
import handleUnsaved from "@/directives/handle-unsaved";

Vue.config.productionTip = false;

Vue.prototype.$API = Api;
// Vue.prototype.$Pusher = Pusher;

Vue.component("apexchart", VueApexCharts);
Vue.use(VueApexCharts);

Vue.use(abilitiesPlugin, ability);

Vue.directive("confirm", confirm);
Vue.directive("handle-unsaved", handleUnsaved);

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

export default vue;
