const meta = {
  module: "auth",
  layout: "auth",
  withoutCredentials: true
};

export default [
  {
    path: `/auth/login`,
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/modules/auth/views/login.vue"),
    meta: {
      ...meta
    }
  }
];
