
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",

  props: {
    executeAction: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    showed: false as boolean,
    action: "" as string,
    title: "" as string,
    text: "" as string,
    sheet: false as boolean,
    params: {} as any
  }),

  methods: {
    open(action: string, title: string, text: string, params: any): void {
      this.action = action;
      this.title = title;
      this.text = text;
      this.params = params;
      this.showed = true;
    },
    close(): void {
      this.showed = false;
    },
    confirm(): void {
      this.executeAction(this.action, this.params);
      this.close();
    },
    cancel(): void {
      this.close();
    }
  }
});
