import Vue from "vue";
import Vuex from "vuex";
import localization from "./modules/localization";
import alert from "./modules/alert";
import preloader from "./modules/preloader";
import authentication from "./modules/authentication";
import navigation from "./modules/navigation";
import user from "./modules/user";
import callAlert from "./modules/callAlert";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    localization,
    callAlert,
    alert,
    authentication,
    user,
    navigation,
    preloader
  },
  strict: process.env.NODE_ENV !== "production"
});
