const meta = {
  module: "APM"
};

export default [
  {
    path: `/apm`,
    redirect: "/apm/page/1"
  },
  {
    path: `/apm/page`,
    redirect: "/apm/page/1"
  },
  {
    path: `/apm/page/:page`,
    name: "apm",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/apm/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
