import APIModel from "../APIModel";

export default class Cards extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "cards";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getClientsBase(query: string): Promise<any> {
    return await this.http.post(`${this.resourceUrl}`, {
      search: query,
      from: 0,
      limit: 10
    });
  }

  async getClientCardById(index: number): Promise<any> {
    return await this.http.get(`card/${index}`, {});
  }

  async getFieldsList() {
    return [
      {
        icon: "mdi-comment",
        text: "Текстовое поле",
        value: 1
      },
      {
        icon: "mdi-calendar",
        text: "Дата",
        value: 2
      },
      {
        icon: "mdi-menu",
        text: "Список",
        value: 3
      },
      {
        icon: "mdi-comment-text",
        text: "Комментарий",
        value: 4
      },
      {
        icon: "mdi-phone",
        text: "Номер телефона",
        value: 5
      }
    ];
  }

  /* POST */

  async create(payload: any): Promise<any> {
    return await this.http.ignoreErrorHandler(422).post(`card/save`, payload);
  }

  async delete(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/delete`, payload);
  }

  /* Patch */

  async update(payload: any): Promise<any> {
    return await this.http.ignoreErrorHandler(422).patch(`card/save`, payload);
  }
}
