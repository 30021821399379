<script>
export default {
  name: "PageLoader"
};
</script>

<template>
  <div class="loader-container">
    <div class="loader">
      <v-progress-circular
        :size="160"
        width="6"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="text-center mt-7 font-weight-light primary--text title">
        Loading...
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 999999;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - 90px);
  left: calc(50% - 90px);
}
</style>
