import APIModel from "../APIModel";

export default class Users extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "users";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async checkStatus(): Promise<any> {
    return await this.http.get(`user`);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getUser(): Promise<any> {
    return await this.http.get(`user`);
  }

  async getRoles(): Promise<any> {
    return [
      {
        text: "Пользователь",
        value: "1"
      },
      {
        text: "Администратор",
        value: "9"
      }
    ];
  }

  async getClientsBase(): Promise<any> {
    return await this.http.post(`cards`, {});
  }

  async getClient(id: number): Promise<any> {
    return await this.http.get(`card/${id}`, {});
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`user/edit/${id}`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http.ignoreErrorHandler(422).post(`user/add`, payload);
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.ignoreErrorHandler().post(`user/delete/${id}`, {});
  }

  async changePassword(id: any, password: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/change_password`, { password });
  }

  async login(endpoint: string, payload: object) {
    return await this.http.post(endpoint, payload);
  }
}
