export default {
  namespaced: true,

  state: {
    show: false as boolean,
    timeout: 4000 as number,
    messages: [] as Array<MessageInterface>
  },

  mutations: {
    show(state: any, payload: MessageInterface) {
      let index: number = state.messages.length;

      if (index) {
        index = state.messages[index - 1].index + 1;
      }

      state.messages.push({ ...payload, index });

      state.show = true;
    },
    hide(state: any, index: number) {
      state.messages.splice(index, 1);
    }
  },

  actions: {
    showSuccess(context: any, text: string) {
      context.commit("show", { text, type: "success" });
      context.dispatch("hideAlert");
    },
    showError(context: any, text: string) {
      context.commit("show", { text, type: "error" });
      context.dispatch("hideAlert");
    },
    showWarning(context: any, text: string) {
      context.commit("show", { text, type: "warning" });
      context.dispatch("hideAlert");
    },
    showInfo(context: any, text: string) {
      context.commit("show", { text, type: "info" });
    },
    hide(context: any, index: number) {
      context.commit("hide", index);
    },
    hideAlert(context: any) {
      setTimeout(() => {
        context.commit("hide");
      }, context.state.timeout);
    }
  },

  getters: {
    show: (state: any): boolean => state.show,
    messages: (state: any): Array<MessageInterface> => state.messages
  }
};
