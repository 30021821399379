import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import ability from "@/plugins/ability";
import API from "@/api/API";

const loadUserInfo = async () => {
  try {
    const {
      data,
      app: { ws_url }
    } = await API.users().checkStatus();
    await store.dispatch("user/setWsUrl", ws_url);
    await store.dispatch("user/set", data);
  } catch (e) {
    await store.dispatch("authentication/logout");
    await store.dispatch("alert/showError", e.message);
  }
};

const loadUserPermissions = async (to: Route) => {
  try {
    const { user } = store.getters["authentication/credentials"];
    const permissions = await import(
      `@/modules/${to.meta?.module}/config/permissions.ts`
    );

    ability.update(permissions.default[user.position]);
  } catch {
    await Promise.resolve();
  }
};

const apply = (router: VueRouter) => {
  router.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) => {
      const isAuthorized = store.getters["authentication/hasAccessToken"];
      const user = store.getters["user/info"];

      if (!to.meta?.withoutCredentials && !isAuthorized) {
        next(`/auth/login`);
        return;
      }

      if (isAuthorized && !user) {
        await loadUserInfo();
      }

      await loadUserPermissions(to);

      next();
    }
  );

  router.afterEach((to: Route, from: Route) => {
    store.dispatch("preloader/hideGlobal");

    if (to.meta?.errorPage) {
      history.replaceState(
        {},
        document.title,
        `/${store.getters["localization/getCurrent"]}${to.redirectedFrom ||
          from.path}`
      );
    }
  });
};

export default {
  apply
};
