const meta = {
  module: "clients"
};

export default [
  {
    path: `/clients`,
    redirect: "/clients/page/1"
  },
  {
    path: `/clients/page`,
    redirect: "/clients/page/1"
  },
  {
    path: `/clients/page/:page`,
    name: "clientsPage",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/clients/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/clients/show/:id`,
    name: "clientsShow",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/clients/views/show.vue"
      ),
    meta: {
      ...meta
    }
  }
];
