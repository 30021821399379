import { AxiosResponse } from "axios";
import APIModel from "../APIModel";

export default class Auth extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "oauth";
  }

  async login(payload: object): Promise<AxiosResponse> {
    return await this.http.ignoreErrorHandler().post(`login`, payload);
  }

  async register(payload: object): Promise<AxiosResponse> {
    return await this.http
      .ignoreErrorHandler()
      .post(`/leads/register`, payload);
  }

  async registerClient(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .post(`/oauth/registration`, payload);
  }

  async forgot(payload: object): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .post(`${this.resourceUrl}/login`, payload);
  }
}
