import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import middleware from "./middleware";

import authentication from "@/router/modules/authentication";
import errors from "@/router/modules/errors";
import general from "@/router/modules/general";
import profile from "@/router/modules/profile";
import users from "@/router/modules/users";
import apm from "@/router/modules/apm";
import clients from "@/router/modules/clients";
import cards from "@/router/modules/cards";
import store from "@/store";

const routes: Array<RouteConfig> = [
  ...errors,
  ...general,
  ...users,
  ...apm,
  ...clients,
  ...cards,
  ...authentication,
  ...profile
];

Vue.use(VueRouter);

store.dispatch("localization/setFromRouter");

const router = new VueRouter({
  mode: "history",
  base: "ru",
  routes
});

middleware.apply(router);

export default router;
