import APIModel from "../APIModel";

export default class APM extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "users";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getStatistics(): Promise<any> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`get-general-statistic`, {});
  }

  async getGroups(): Promise<any> {
    return await this.http.ignoreGlobalPreloader().get(`get-groups`, {});
  }

  async getOperators(): Promise<any> {
    return await this.http.ignoreGlobalPreloader().get(`get-operators`, {});
  }

  async getOperatorsByGroup(query: string): Promise<any> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`get-operators-by-group/${query}`, {});
  }

  async getClientsBase(query: string): Promise<any> {
    return await this.http.post(`cards`, { search: query, from: 0, limit: 10 });
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`user/edit/${id}`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http.ignoreErrorHandler(422).post(`user/add`, payload);
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.ignoreErrorHandler().post(`user/delete/${id}`, {});
  }

  async changePassword(id: any, password: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .patch(`${this.resourceUrl}/${id}/change_password`, { password });
  }

  async login(endpoint: string, payload: object) {
    return await this.http.post(endpoint, payload);
  }
}
