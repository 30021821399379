import APIModel from "../APIModel";

export default class Clients extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "clients";
  }

  /* GET */

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getClient(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 403, 500)
      .get(`client/${id}`, {});
  }

  async getClients(payload: any, params: object): Promise<any> {
    return await this.http
      .setParams(params)
      .post(`${this.resourceUrl}`, payload);
  }

  /* PATCH */

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${payload.id}`, payload);
  }

  /* POST */
  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422, 404, 403, 500, 400)
      .post(`client/${id}`, payload);
  }

  async deleteRows(payload: object): Promise<any> {
    return await this.http.post(`${this.resourceUrl}/delrows`, payload);
  }

  async clearBase(id: number): Promise<any> {
    return await this.http.post(`${this.resourceUrl}/truncate/${id}`, {});
  }

  async getClientsBase(query: string): Promise<any> {
    return await this.http.post(`cards`, { search: query, from: 0, limit: 10 });
  }

  async exportFile(payload: any, headers: any): Promise<any> {
    return await this.http.post(`clients/export`, payload, headers);
  }

  async exportHeaders(payload: any, headers: any): Promise<any> {
    return await this.http.post(`clients/template`, payload, headers);
  }

  async importFile(payload: any): Promise<any> {
    return await this.http.post(`clients/import`, payload);
  }
}
