const meta = {
  module: "cards"
};

export default [
  {
    path: `/cards`,
    redirect: "/cards/page/1"
  },
  {
    path: `/cards/page`,
    redirect: "/cards/page/1"
  },
  {
    path: `/cards/page/:page`,
    name: "cards",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/cards/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/cards/show`,
    name: "cards-show",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/cards/views/show.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/cards/edit`,
    name: "cards-edit",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/cards/views/edit.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/cards/create`,
    name: "cards-create",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "@/modules/cards/views/create.vue"
      ),
    meta: {
      ...meta
    }
  }
];
