import APIModel from "../APIModel";

export default class Cards extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "operator";
  }

  async pause(operator_id: number, params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404)
      .post(`${this.resourceUrl}/pause/${operator_id}`, params);
  }

  async unpause(operator_id: number, params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404)
      .post(`${this.resourceUrl}/unpause/${operator_id}`, params);
  }

  async pauseStatus(operator_id: number, params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404)
      .get(`${this.resourceUrl}/pause-status/${operator_id}`, params);
  }
}
