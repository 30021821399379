
import Vue from "vue";
import AppLayout from "@/layouts/index.vue";
import PageLoader from "@/components/navigation/PageLoader.vue";
import startCase from "lodash/fp/startCase";

export default Vue.extend({
  name: "App",

  components: { PageLoader, AppLayout },

  data: () => ({
    startCase,
    userStatusChecked: false
  }),

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.setPageTitle();
      }
    }
  },

  computed: {
    showGlobalLoader(): boolean {
      return this.$store.getters["preloader/showGlobal"];
    }
  },

  methods: {
    setPageTitle() {
      const localizedTitle = `${this.$route.meta?.module}.title` as string;

      let title = localizedTitle;

      if (localizedTitle.includes(".")) {
        title = this.startCase(this.$route.meta?.module);
      }

      if (title) {
        document.title = `${process.env.VUE_APP_NAME} - ${title}`;
      } else {
        document.title = `${process.env.VUE_APP_NAME}`;
      }
    }
  }
});
