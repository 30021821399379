export default {
  namespaced: true,

  state: {
    show: false as boolean,
    showCard: false as boolean,
    update: false as boolean,
    timeout: 20000,
    messages: [] as Array<any>
  },

  mutations: {
    async show(state: any, payload: any) {
      let index = state.messages.length;

      if (index) {
        index = state.messages[index - 1].index + 1;
      }

      if (
        payload.text.dst &&
        !state.messages.some((item: any) => payload.text.src === item.text.src)
      ) {
        state.messages.push({ ...payload, index });
      }

      if (!payload.hasOwnProperty("withoutClosing")) {
        setTimeout(() => {
          state.messages.splice(
            state.messages.findIndex((item: any) => item.index === index),
            1
          );
        }, state.timeout);
      }
      state.show = true;
    },
    hide(state: any, payload: any | number) {
      if (payload.cardId) {
        state.messages.splice(payload.index, 1);
        return;
      }
      state.messages.splice(payload, 1);
    },
    update(state: any, payload: boolean) {
      state.update = payload;
    },
    showCard(state: any, payload: boolean) {
      state.showCard = payload;
    }
  },

  actions: {
    /**
     * @param commit
     * @param {Object} payload - Alert object
     * @param {string} payload.text
     * @param {string} payload.color
     * @param {string} payload.icon
     * @param {boolean} payload.withoutClosing
     * @param {boolean} payload.enableSound
     * @param {Function} payload.onClick
     * @param {string} payload.title
     * @param {Array} payload.list
     * */
    showSuccess({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "success" });
      } else {
        commit("show", { ...payload, type: "success" });
      }
    },
    showError({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "error" });
      } else {
        commit("show", { ...payload, type: "error" });
      }
    },
    showWarning({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "warning" });
      } else {
        commit("show", { ...payload, type: "warning" });
      }
    },
    showInfo({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "info" });
      } else {
        commit("show", { ...payload, type: "info" });
      }
    },
    hide({ commit }: any, payload: any | number) {
      if (payload.cardId) {
        commit("hide", { index: payload.index, cardId: payload.cardId });
      } else {
        commit("hide", payload);
      }
    },
    showCard({ commit }: any, payload: boolean) {
      commit("showCard", payload);
    },
    update({ commit }: any) {
      commit("update", true);

      setTimeout(() => {
        commit("update", false);
      }, 500);
    }
  },

  getters: {
    show: (state: any) => state.show,
    messages: (state: any) => state.messages,
    update: (state: any) => state.update,
    showCard: (state: any) => state.showCard
  }
};
